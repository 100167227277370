<template>
    <div v-if="order">
        <h2><router-link :to="{ name: 'saleorder' }">รายการขาย</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}รายการขาย {{ order.orderNumber }}</h2>
        <v-row class="mt-4">
          <v-col>
            <v-card>
              <v-form v-model="isFormValid">
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        label="เป็นบิล COD"
                        v-model="order.is_cod"
                        :disabled="!order.isCODEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.salechannel"
                        label="ช่องทางการขาย"
                        placeholder="พิมพ์เพื่อค้นหาช่องทางการขาย"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/salechannelList'"
                        dense
                        return-object
                        ref="salechannelAutocomplete"
                        item-text="name"
                        item-value="id"
                        :disabled="!order.isSaleChannelChangeable || $store.getters['app/GET_APP_MODE'] == 'read'"   
                        hide-details="auto"                     
                      ></delay-autocomplete>
                    </v-col>
                    <v-col>
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.warehouse"
                        label="คลัง"
                        placeholder="พิมพ์เพื่อค้นหาคลัง (เว้นว่างหากไม่เลือก)"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/warehouseList'"
                        dense
                        return-object
                        ref="warehouseAutocomplete"                        
                        item-text="name"
                        item-value="id"
                        :disabled="!order.isWarehouseChangeable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        :lazySearch="true"
                        hide-details="auto"
                      ></delay-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">                      
                      <v-text-field
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.sns_name"
                        label="ชื่อในโซเชียล"                                            
                        dense                        
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea
                        outlined
                        v-model="order.note"
                        label="หมายเหตุร้านค้า"
                        placeholder="หมายเหตุของร้านค้า *ลูกค้าไม่เห็นตรงนี้"
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      ></v-textarea>
                    </v-col>                    
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col>
                      <product-input
                      v-model="order"
                      :url="'saleorder'"
                      :warehouse_id="!!order.warehouse ? order.warehouse.id : null"
                      :disabled="!order.isItemEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                      ></product-input>
                    </v-col>              
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <AooVue2Combobox
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.name"
                        :label="'ชื่อ - นามสกุล' + (order.is_cod ? ' *' : '')"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/customerListByName'"
                        dense
                        ref="customerNameCombobox"                    
                        item-text="name"
                        item-value="name"
                        @select="selectCustomer"
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.requiredIf(order.name, order.is_cod, true)]"
                      ></AooVue2Combobox>
                      <!--
                      <delay-combobox
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.name"
                        label="ชื่อ - นามสกุล"                    
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/customerListByName'"
                        dense
                        ref="customerNameCombobox"                    
                        item-text="name"
                        item-value="name"
                        :filter="filterCustomerByName"
                        :lazySearch="true"
                        @change="selectCustomer"
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </delay-combobox>
                      -->
                    </v-col>  
                    <v-col cols="12" md="6">
                      <AooVue2Combobox
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.tel"
                        :label="'เบอร์' + (order.is_cod ? ' *' : '')"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/customerListByTel'"
                        dense
                        ref="customerTelCombobox"                    
                        item-text="tel"
                        item-value="tel"                        
                        @select="selectCustomer"
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.requiredIf(order.name, order.is_cod, true)]"
                      ></AooVue2Combobox>
                      <!--
                      <delay-combobox
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.tel"
                        label="เบอร์"                    
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/customerListByTel'"
                        dense
                        ref="customerTelCombobox"                    
                        item-text="tel"
                        item-value="tel"
                        :filter="filterCustomerByTel"
                        :lazySearch="true"
                        @change="selectCustomer"
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </delay-combobox>
                      -->
                    </v-col>                    
                  </v-row>
                  <v-row>                    
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="order.email"
                        outlined
                        label="อีเมล"                                    
                        hide-details="auto"
                        dense
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                        <v-text-field 
                            v-model="order.address"                         
                            :label="'ที่อยู่' + (order.is_cod ? ' *' : '')"
                            outlined
                            dense
                            hide-details="auto"
                            :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                            :rules="[validators.requiredIf(order.name, order.is_cod, true)]"
                        ></v-text-field>
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                        <VThailandAddressAutoComplete 
                        hide-details="auto" 
                        dense v-model="order.city" 
                        :placeholder="'แขวง/ตำบล' + (order.is_cod ? ' *' : '')" 
                        name="city" 
                        type="district" 
                        @select="setAddress"
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.requiredIf(order.name, order.is_cod, true)]"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <VThailandAddressAutoComplete 
                        hide-details="auto" 
                        dense 
                        v-model="order.county" 
                        :placeholder="'เขต/อำเภอ' + (order.is_cod ? ' *' : '')" 
                        name="county" 
                        type="amphoe" 
                        @select="setAddress"
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.requiredIf(order.name, order.is_cod, true)]"
                        />
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                        <VThailandAddressAutoComplete 
                        hide-details="auto" 
                        dense 
                        v-model="order.province" 
                        :placeholder="'จังหวัด' + (order.is_cod ? ' *' : '')" 
                        name="province" 
                        type="province" 
                        @select="setAddress"
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.requiredIf(order.name, order.is_cod, true)]"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <VThailandAddressAutoComplete 
                        hide-details="auto" 
                        dense 
                        v-model="order.postcode" 
                        :placeholder="'เลขไปรษณีย์' + (order.is_cod ? ' *' : '')" 
                        name="postcode" 
                        type="zipcode" 
                        @select="setAddress"
                        :disabled="!order.isDeliveryInfoEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        :rules="[validators.requiredIf(order.name, order.is_cod, true)]"
                        />
                    </v-col>                    
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox
                      label="เป็นสินค้า preorder"
                      v-model="order.is_preorder"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-card-actions class="justify-end">
                <v-btn
                color="secondary"               
                text                 
                large
                :loading="sendingData"
                @click="submitForm(true)"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read' && order.status == 'draft'"
                >
                บันทึกแบบร่าง
                </v-btn>
                <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.push({ name: 'saleorder' })"
                >
                กลับ
                </v-btn>
                <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid || order.orderItems.length == 0"
                :loading="sendingData"
                @click="submitForm(false)"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read' && order.status != 'cancelled'"
                >
                {{ $store.getters['app/GET_APP_MODE'] == 'create' || order.status == 'draft' ? "สร้าง" : "แก้ไข"}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col 
          v-if="$store.getters['app/GET_APP_MODE'] != 'create' && order.status != 'draft'"
          cols="12"
          sm="3"
          >
            <v-card>
              <v-card-title>สถานะ: {{ order.status | orderStatusToText }}</v-card-title>
              <div class="px-4">
                <copy-box
                  dense
                  outlined
                  :value="order.link"
                ></copy-box>

                <v-select
                  v-model="currentStatus"
                  :items="statusList"
                  item-text="text"
                  item-value="value"
                  label="ปรับสถานะบิล"
                  outlined
                  dense
                  @change="changeStatus"
                  :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                ></v-select>                
              </div>              
            </v-card>
            <v-card class="mt-2 pb-4" v-if="order.status == 'dispatched' || order.status == 'pending_delivery' || order.status == 'cancelled'">
              <v-card-title>การจัดส่ง</v-card-title>
              <div class="px-4">
                <v-text-field
                  v-model="order.trackingNo"
                  outlined
                  label="หมายเลขพัสดุ"                                    
                  hide-details="auto"
                  dense
                  :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                ></v-text-field>        
              </div>
            </v-card>
            <v-card class="mt-2 pb-4" v-if="order.isPaid">
              <v-card-title>การชำระ</v-card-title>
              <div class="px-5" v-if="order.paymentType == 'transfer'">
                <img v-if="!!order.paymentInfo.info.bank.logo" :src="order.paymentInfo.info.bank.logo" alt="">
                <div>
                  <span>ธนาคาร: </span><span>{{ order.paymentInfo.info.bank.name }}</span>
                </div>
                <div>
                  <span>สาขา: </span><span>{{ !!order.paymentInfo.branch ? order.paymentInfo.branch : '-' }}</span>
                </div>
                <div>
                  <span>จำนวนเงิน: </span><span>{{ order.amountTransferred }}</span>
                </div>
                <div>
                  <label for="">หลักฐานการชำระ</label>
                </div>
                <light-box v-if="!!order.slipImg" :src="order.slipImg" ></light-box>                
              </div>
              <div class="px-5" v-else-if="order.paymentType != 'cod'">
                <div>
                    <v-img src="https://www.gbprimepay.com/dist/images/logo.svg" max-height="100" max-width="100"></v-img>                    
                </div>
                <div><span>หมายเลขอ้างอิง: </span><span>{{ order.paymentReferral }}</span></div>
                <div v-if="!!order.creditCardNo"><span>เลขบัตรเครดิต: </span><span>{{ order.creditCardNo }}</span></div>                
              </div>
              <div v-else>
                <h1>เก็บเงินปลายทาง</h1>                
              </div>
            </v-card>
            <v-card class="mt-2 pb-4" v-if="order.history">
              <v-card-title>ประวัติ</v-card-title>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        วันที่
                      </th>
                      <th class="text-center">
                        สถานะ
                      </th>
                      <th class="text-center">
                        โดย
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in order.history"
                      :key="key"
                    >
                      <td>{{ item.created_at | timestampToText }}</td>
                      <td class="text-center">{{ item.status | orderStatusToText }}</td>
                      <td>{{ item.creator ? item.creator.name + ' ' + item.creator.lastname : 'ระบบ' }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
        <v-dialog
          v-model="changeStatusDialog.isShow"
          persistent
          transition="dialog-top-transition"
          max-width="600"
        >
          <v-card>
            <v-card-title>{{ changeStatusDialog.title }}</v-card-title>
            <v-card-text>{{ changeStatusDialog.body }}</v-card-text>
            <v-card-actions class="justify-end">              
              <v-btn outlined color="secondary" 
              @click="cancelChangeStatus"
              :disabled="sendingData"
              >ยกเลิก</v-btn>
              <v-btn color="primary" @click="changeOrderStatus"
              :loading="sendingData"
              >ยืนยัน</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { Order } from '@/classes/order/Order'
import AooVue2Combobox from '@/components/AooVue2Combobox.vue'
import DelayCombobox from '@/components/DelayCombobox.vue'
import LightBox from '@/components/LightBox.vue'
import ProductInput from '@/components/order/ProductInput.vue'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { convertOrderStatusToText } from '@/helpers/converter'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
  requiredIf,
} from '@core/utils/validation'
import { isString } from '@vueuse/shared'
export default {
  data() {
    return {
      orderNumber: null,
      isFormValid: false,
      sendingData: false,
      order: null,
      salechannel: null,
      warehouse: null,
      currentStatus: null,
      changeStatusDialog: {
        isShow: false,
        title: '',
        body: '',
      },
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
        requiredIf,
      },
    }
  },
  async created() {
    const saleorder_id = this.$route.params.saleorder_id

    if (saleorder_id == 'create' && !this.$route.query.duplicate) {
      // New order
      this.order = new Order({})
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    }
    if (saleorder_id == 'create' && !!this.$route.query.duplicate) {
      // Duplicate
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        const response = await asyncGet('/saleorder/' + this.$route.query.duplicate)

        delete response.status
        delete response.orderNumber

        this.convertDataFromServerToForm(response)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)

      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'OnlineOrder'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/saleorder/' + saleorder_id)

      this.convertDataFromServerToForm(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
    this.currentStatus = this.order.status
  },
  computed: {
    statusList() {
      if (this.order.status == 'cancelled')
        return [
          {
            text: 'ยกเลิก',
            value: 'cancelled',
          },
        ]

      if (this.order.status == 'new' || this.order.status == 'pending_info' || this.order.status == 'pending_confirm')
        return [
          {
            text: 'บิลใหม่',
            value: 'new',
          },
          {
            text: 'รอที่อยู่',
            value: 'pending_info',
          },
          {
            text: 'รอชำระเงิน',
            value: 'pending_confirm',
          },
          {
            text: 'ยกเลิก',
            value: 'cancelled',
          },
        ]

      if (this.order.status == 'pending_delivery' || this.order.status == 'dispatched')
        return [
          {
            text: 'รอจัดส่ง',
            value: 'pending_delivery',
          },
          {
            text: 'ส่งแล้ว',
            value: 'dispatched',
          },
          {
            text: 'ยกเลิก',
            value: 'cancelled',
          },
        ]

      return [
        {
          text: 'บิลใหม่',
          value: 'new',
        },
        {
          text: 'รอข้อมูลจัดส่ง',
          value: 'pending_info',
        },
        {
          text: 'รอชำระเงิน',
          value: 'pending_confirm',
        },
        {
          text: 'รอจัดส่ง',
          value: 'pending_delivery',
        },
        {
          text: 'ส่งแล้ว',
          value: 'dispatched',
        },
        {
          text: 'ยกเลิก',
          value: 'cancelled',
        },
      ]
    },
  },
  methods: {
    async submitForm(is_draft = false) {
      this.sendingData = true
      try {
        const sendData = {
          ...this.order.serialize(),
          is_draft: is_draft,
        }

        const saleorder_id = this.$route.params.saleorder_id
        if (saleorder_id == 'create') {
          const retResult = await asyncPostAsJson('/saleorder', sendData)

          this.$router.push({
            name: 'saleorder',
            query: {
              orderNumber: retResult.orderNumber,
              orderId: retResult.id,
              viewToken: retResult.viewToken,
            },
          })
        } else {
          sendData['_method'] = 'PUT'
          await asyncPostAsJson('/saleorder/' + saleorder_id, sendData)

          this.$router.push({ name: 'saleorder' })
        }
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    convertDataFromServerToForm(orderDataFromServer) {
      this.order = new Order(orderDataFromServer)

      const { products } = orderDataFromServer

      // this.warehouse = warehouse

      /*
      const order = this.order

      products.forEach(element => {
        const orderItem = new OrderItem(
          element.id,
          element.images && !!element.images[0] ? element.images[0].URI : null,
          element.name,
          element.sku,
          element.barCode,
          element.pivot.note,
          element.pivot.quantity,
          element.pivot.price,
          element.pivot.discount,
          element.pivot.discount_type,
          element.pivot.vat,
        )

        order.addOrderItem(orderItem)
      })
      */
    },
    setAddress(address) {
      this.order.city = address.district
      this.order.county = address.amphoe
      this.order.province = address.province
      this.order.postcode = address.zipcode
    },
    filterCustomerByName(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    filterCustomerByTel(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.tel && item.tel.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    filterCustomerBySNSName(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    selectCustomer(payload) {
      if (!payload || isString(payload)) return

      const { name, email, address, city, county, postcode, province, tel } = payload

      if (!name || !tel) return

      this.order.tel = tel
      this.order.name = name
      this.order.address = address
      this.order.email = email
      this.order.county = county
      this.order.city = city
      this.order.postcode = postcode
      this.order.province = province
    },
    selectCustomerBySNSName(payload) {
      if (!payload || isString(payload)) return

      const { name, email, address, city, county, postcode, province, tel } = payload.customer

      this.order.tel = tel
      this.order.name = name
      this.order.address = address
      this.order.email = email
      this.order.county = county
      this.order.city = city
      this.order.sns_name = payload.name
      this.order.postcode = postcode
      this.order.province = province
    },
    changeStatus(payload) {
      this.changeStatusDialog.title = 'เปลี่ยนสถานะเป็น "' + convertOrderStatusToText(payload) + '" ?'
      this.changeStatusDialog.body =
        'คุณต้องการเปลี่ยนสถานะจาก "' +
        convertOrderStatusToText(this.order.status) +
        '" เป็น "' +
        convertOrderStatusToText(payload) +
        '" หรือไม่ ?'
      this.changeStatusDialog.isShow = true
    },
    cancelChangeStatus() {
      this.changeStatusDialog.isShow = false
      this.changeStatusDialog.title = ''
      this.changeStatusDialog.body = ''
      this.currentStatus = this.order.status
    },
    async changeOrderStatus() {
      this.sendingData = true
      try {
        const sendData = {
          _method: 'PUT',
          toStatus: this.currentStatus,
        }
        const saleorder_id = this.$route.params.saleorder_id

        await asyncPostAsJson('/saleorder/' + saleorder_id + '/changeOrderStatus', sendData)

        this.$router.push({ name: 'saleorder' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
  },
  components: {
    ProductInput,
    DelayCombobox,
    LightBox,
    AooVue2Combobox,
  },
}
</script>,